import { useMemo } from "react";

import { TermSearchType } from "@sellernote/shared/src/hooks/admin/useSearchWithTerm";

/**
 * 쉽다와 보풀 어드민의 API 구조가 달라서 쉽다 어드민에서도 useSearchWithTerm값을 사용할 수 있게 해준다.
 * @param termSearchType useSearchWithTerm에서 들어오는 검색종류 key 값
 * @param debouncedSearchTerm useSearchWithTerm에서 지연시킨 검색어 입력 값
 *
 */
export default function useGetObjectWithTermSearchTypeKey<T extends string>({
  termSearchType,
  debouncedSearchTerm,
}: {
  termSearchType: TermSearchType<T> | undefined;
  debouncedSearchTerm: unknown;
}) {
  const objectWithTermSearchTypeKey = useMemo(() => {
    if (termSearchType?.value && debouncedSearchTerm) {
      const searchObject: { [key: string]: unknown } = {};
      searchObject[termSearchType?.value] = debouncedSearchTerm;
      return searchObject;
    }
    return undefined;
  }, [debouncedSearchTerm, termSearchType?.value]);

  return { objectWithTermSearchTypeKey };
}
