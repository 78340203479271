import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  MarkerInfo,
  SvgTypeIconInfo,
} from "@sellernote/shared/src/components/googleMap/GoogleMapWithCluster";
import { APP_NAME } from "@sellernote/shared/src/constants";
import {
  Coordinate,
  FreightType,
} from "@sellernote/shared/src/types/common/common";
import { BidPortMap } from "@sellernote/shared/src/types/forwarding/bid";
import { isEmptyObjectOrArray } from "@sellernote/shared/src/utils/common/etc";
import { findNearestIndex } from "@sellernote/shared/src/utils/common/googleMap";
import {
  createInfoContainer,
  createWaypointMarker,
} from "@sellernote/shared/src/utils/forwarding/map";

import { TrackingShip } from "../../types/forwarding/map";
import { useCheckIsMobile } from "../../utils/common/hook";

import { ShipdaCurrentLanguage } from "../../i18n/i18nForShipda";

export default function useShipmentMapMarkerInfoList({
  trackingShipResult,
  freightType,
}: {
  trackingShipResult: TrackingShip | undefined;
  freightType: FreightType | undefined;
}) {
  const { t } = useTranslation();

  const { isMobile } = useCheckIsMobile();

  function getBidMapPortCoordinate(port?: BidPortMap): Coordinate | undefined {
    if (!port || !port.lat || !port.lng) {
      return;
    }

    return {
      lat: port.lat,
      lng: port.lng,
      name:
        ShipdaCurrentLanguage.currentLanguage === "ko"
          ? port.name || port.nameEN
          : port.nameEN,
    };
  }

  /** 맵의 센터 포지션 */
  const pinCenter = useMemo(() => {
    /** 현재 운송 진행 중 여부 */
    if (
      trackingShipResult?.pin &&
      trackingShipResult.pin.lat &&
      trackingShipResult.pin.lng
    ) {
      return {
        lat: trackingShipResult.pin.lat,
        lng: trackingShipResult.pin.lng,
      };
    }

    return;
  }, [trackingShipResult]);

  const polylineData = useMemo(() => {
    if (!trackingShipResult) return;

    if (isEmptyObjectOrArray(trackingShipResult.routes)) return;

    return trackingShipResult.routes.flatMap((routeArray) =>
      routeArray.map((port) => ({ lat: port.lat, lng: port.lng }))
    );
  }, [trackingShipResult]);

  const splitIndex = useMemo(() => {
    if (!trackingShipResult) return;

    if (!polylineData) return;

    if (!trackingShipResult.pin) return;

    return findNearestIndex({
      pinLat: trackingShipResult.pin.lat,
      pinLng: trackingShipResult.pin.lng,
      routes: polylineData,
    });
  }, [polylineData, trackingShipResult]);

  /** 출발지 위도, 경도를 구하는 함수 */
  const getDeparture = useCallback((): Coordinate | undefined => {
    const targetPort = trackingShipResult?.startPort;

    if (!targetPort) return undefined;

    return getBidMapPortCoordinate(targetPort);
  }, [trackingShipResult?.startPort]);

  /** 도착지 위도, 경도를 구하는 함수 */
  const getDestination = useCallback((): Coordinate | undefined => {
    const targetPort = trackingShipResult?.endPort;

    if (!targetPort) return undefined;

    return getBidMapPortCoordinate(targetPort);
  }, [trackingShipResult?.endPort]);

  /** 경유지를 구하는 함수 */
  const getStopovers = useCallback((): Coordinate[] | undefined => {
    if (!trackingShipResult) return;

    if (isEmptyObjectOrArray(trackingShipResult)) return;

    if (!trackingShipResult.pin) return;

    // 항적(폴리라인)이 있다면 마커의 위치를 실선 폴리라인의 마지막 좌표로 설정
    if (polylineData && splitIndex) {
      return [polylineData[splitIndex]];
    }

    return [
      {
        lat: trackingShipResult.pin.lat,
        lng: trackingShipResult.pin.lng,
      },
    ];
  }, [polylineData, splitIndex, trackingShipResult]);

  /** 현재 이동중인 화물 위도, 경도 */
  const shipPosition = useMemo((): Coordinate | undefined => {
    if (!trackingShipResult) return;

    if (!trackingShipResult.pin) return;

    const stopovers = getStopovers();

    if (!stopovers?.length) {
      return getDeparture();
    }

    return stopovers[0];
  }, [getDeparture, getStopovers, trackingShipResult]);

  const markerInfoList: MarkerInfo[] | undefined = useMemo(() => {
    if (!trackingShipResult) return;

    const parser = new DOMParser();

    const departure = getDeparture();
    const destination = getDestination();

    const infoIconImgSrc = (() => {
      if (freightType === "AIR") {
        return APP_NAME === "shipda-kr"
          ? "/assets/images/mypage/bid/marker-info-icon-plane.svg"
          : "/images/trello/marker-info-icon-plane.svg";
      }

      return APP_NAME === "shipda-kr"
        ? "/assets/images/mypage/bid/marker-info-icon-ship.svg"
        : "/images/trello/marker-info-icon-ship.svg";
    })();

    const waypointIconImgSrc = (() => {
      return APP_NAME === "shipda-kr"
        ? "/assets/images/mypage/bid/ellipsis-solid.svg"
        : "/images/trello/ellipsis-solid.svg";
    })();

    const departureInfo = createInfoContainer({
      className: "departure",
      titleText: t("page-mypage-bid:운송관리_공통_TRACKING_MAP_출발지"),
      portName: departure?.name,
      infoIconImgSrc,
    });

    const destinationInfo = createInfoContainer({
      className: "destination",
      titleText: t("page-mypage-bid:운송관리_공통_TRACKING_MAP_도착지"),
      portName: destination?.name,
      infoIconImgSrc,
    });

    const shipSvg = `<svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Polygon 4" d="M6 0L12 18L6 15L0 18L6 0Z" fill="#D65481"/>
    </svg>
    `;

    const stopShipSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 9.21012C18 4.13177 13.9625 0 9.00004 0C4.03756 0 0 4.13177 0 9.21012C0 11.3012 0.670344 13.2733 1.93821 14.9143L9.00004 24L16.0599 14.9162C17.3297 13.2733 18 11.3012 18 9.21012ZM9 12.6C10.9882 12.6 12.6 10.9882 12.6 9C12.6 7.01178 10.9882 5.4 9 5.4C7.01178 5.4 5.4 7.01178 5.4 9C5.4 10.9882 7.01178 12.6 9 12.6Z" fill="#94355B"/>
    </svg>
    `;

    const portSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6" r="5" fill="#DD6E97" stroke="#C03D69" stroke-width="2"/>
    </svg>
    `;

    const parsedShipSvg = parser.parseFromString(
      shipSvg,
      "image/svg+xml"
    ).documentElement;

    const parsedStopShipSvg = parser.parseFromString(
      stopShipSvg,
      "image/svg+xml"
    ).documentElement;

    const parsedDepartureSvg = parser.parseFromString(
      portSvg,
      "image/svg+xml"
    ).documentElement;

    const parsedDestinationPortSvg = parser.parseFromString(
      portSvg,
      "image/svg+xml"
    ).documentElement;

    parsedShipSvg.style.transform = `translate(5%, 55%) rotate(${
      // heading 값이 null일 때가 있음
      trackingShipResult.pin?.heading || 0
    }deg)`;
    parsedShipSvg.style.transformOrigin = "center";

    parsedStopShipSvg.style.transform = "translate(0%, 15%)";

    parsedDepartureSvg.style.position = "absolute";
    parsedDepartureSvg.style.transform = "translate(-50%, -50%)";

    parsedDestinationPortSvg.style.position = "absolute";
    parsedDestinationPortSvg.style.transform = "translate(-50%, -50%)";

    // 환적지 정보는 여러개 올 수 있기 때문에 리스트로 표기
    const waypointInfoList = trackingShipResult.waypoints
      .map((waypoint) => {
        const infoContainer = createInfoContainer({
          className: "waypoint",
          titleText: t("hooks:useShipmentMapMarkerInfoList.환적지"),
          portName: waypoint.name,
          infoIconImgSrc: waypointIconImgSrc,
        });

        // 환적지 마커는 최초 표기하지 않도록 처리
        infoContainer.style.display = "none";

        return [
          // 환적지 정보
          {
            lat: waypoint.lat,
            lng: waypoint.lon,
            markerInfo: infoContainer,
            title: waypoint.name + "-info",
            zIndex: 1,
          },

          // 환적지 Marker
          {
            lat: waypoint.lat,
            lng: waypoint.lon,
            title: waypoint.name + "-marker",
            markerInfo: createWaypointMarker(),

            onClick: () => {
              if (isMobile) {
                infoContainer.style.display = "block";
              }
            },
            onClickOutSide: () => {
              if (isMobile) {
                infoContainer.style.display = "none";
              }
            },
            onMouseOver: () => {
              if (!isMobile) {
                infoContainer.style.display = "block";
              }
            },
            onMouseOut: () => {
              if (!isMobile) {
                infoContainer.style.display = "none";
              }
            },
          },
        ];
      })
      .flat();

    return [
      ...(departure
        ? [
            {
              ...departure,
              iconInfo: {
                type: "svg",
                svg: parsedDepartureSvg,
              } as SvgTypeIconInfo,
              title: "departureIcon",
            },
            {
              ...departure,
              markerInfo: departureInfo,
              title: "departureInfo",
              zIndex: 1,
            },
          ]
        : []),

      ...(destination
        ? [
            {
              ...destination,
              iconInfo: {
                type: "svg",
                svg: parsedDestinationPortSvg,
              } as SvgTypeIconInfo,
              title: "destinationIcon",
            },
            {
              ...destination,
              markerInfo: destinationInfo,
              title: "destinationInfo",
              zIndex: 1,
            },
          ]
        : []),

      ...(shipPosition?.lat && shipPosition?.lng
        ? [
            {
              ...shipPosition,
              iconInfo: {
                type: "svg",
                svg: trackingShipResult.pin?.showVesselIcon
                  ? parsedShipSvg
                  : parsedStopShipSvg,
              } as SvgTypeIconInfo,
              title: "shipPosition",
            },
          ]
        : []),

      ...(waypointInfoList.length ? waypointInfoList : []),
    ];
  }, [
    freightType,
    getDeparture,
    getDestination,
    isMobile,
    shipPosition,
    t,
    trackingShipResult,
  ]);

  return {
    markerInfoList,
    pinCenter,
  };
}
